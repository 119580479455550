.mainContainer {
    width: 100vw;
    height: 100vh;
    position: relative;

    /*background-color: #0f2e5d;*/
    background-image: url("/public/images/bkg.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    font-family: "futura-pt", sans-serif;
    color: white;
    overflow: auto;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    margin-top: 0;
}

.inputHeader {
    display: flex;
    flex-direction: row;
    justify-items: center;
    width: 100%;
    margin-top: 0vh;
    margin-bottom: 0vh;
}


.logo {
    position: absolute;
    margin-top: 12vh;
    margin-bottom:0vh;
    width: 40vw;
}

.logoSmall {
    margin-top: 2vh;
    width: 20%;
}

.banner {
    position: absolute;
    width: 50%;
    bottom: 10vh;
}
.videoContainer {
    margin-top: 1.5vh;
    margin-bottom: 0vh;
    width: 60vw;
    aspect-ratio: 16 / 9;
    display: flex;
    align-content: center;
    flex-direction: column;
}

.alternativeContainer {
    width: 60vw;
}

.hrLine {
    width: 60vw;
}

.qeaContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.qeaIcon {
    filter: invert(1);
    width: 2vw;
    height: fit-content;
}

.topLine {
    margin-top: -5%;
    width: 140%;
    transform: scale(-1, -1);
}

.bottomLine {
    margin-top: 2%;
    width: 140%;
}

h3 {
    font-size: 1.5vw;
}

.alertContainer {
    position: absolute;
    bottom: 15vh;
}

@media only screen and (max-width:600px) {

    .mainContainer {
        height: 100vh;
    }

    .videoContainer {
        margin-top: 2%;
        width: 90vw;
        height: calc(90vw * 9 / 16);
        display: flex;
        align-content: center;
    }

    .qeaContainer {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }

    .inputContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90vw;
    }

    .alternativeContainer {
        width: 90vw;
    }

    .hrLine {
        width: 90vw;
    }


    .logo {
        margin-top: 12vh;
        width: 85vw;
    }

    .logoSmall {
        width: 50%;
    }

    .banner {
        width: 80%;
    }

    .qeaIcon {
        width: 8vw;
    }

    h3 {
        font-size: 6vw;
    }

    .alertContainer {
        position: absolute;
        bottom: 15vh;
    }
}