.container {
    background-color: white;
    width: 40vw;
    height: 55vh;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 2%;
    overflow: hidden;
    position: relative;
}

.alert {
    position: absolute;
    width: 40vw;
    bottom: 5%;
}

@media only screen and (max-width:600px) {
    .container {
        width: 90vw;
        height: 70vh;
    }

    h3 {
        font-size: 5vw;
    }

    .alert {
        width: 85vw;
        bottom: 2%;
    }
}
